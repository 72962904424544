<template lang="pug">
    el-col#D1(:span="8")
        el-row.panel-group 
            el-col.card-panel-col
                
                el-row.card-panel
                    div.title {{stats.todayCntTitle}} / {{stats.totalCntTitle}}
                    el-col.card-panel-description(:span="10")
                        count-to( :start-val="0" :end-val="stats.todayCnt" :duration="1500").card-panel-num
                        div.card-panel-text {{stats.todayCntTitle}}
                    el-col.card-panel-description(:span="10")
                        count-to( :start-val="0" :end-val="stats.totalCnt" :duration="1500").card-panel-num
                        div.card-panel-text {{stats.totalCntTitle}}
</template>

<script>
import CountTo from "vue-count-to";
import { getWidget } from "@/api/dashboard";

export default {
  name: "data-widget",
  props: ["date", "id"],
  components: { CountTo },
  data: () => ({ stats: { day_cnt: 0, all_cnt: 0 } }),
  async created() {
    const resp = await getWidget({ id: this.id, date: this.date });
    this.stats = resp.data.data;
  },
  methods: {
    async refresh() {
      const resp = await getWidget({ id: this.id, date: this.date });
      this.stats = resp.data.data;
    }
  },
  watch: {
    date: async function(date) {
      if (!date) return;
      this.refresh();
    }
  }
};
</script>

<style lang="scss">
#D1 {
  .panel-group {
    margin-top: 18px;
    padding-left: 30px;
    height: 200px;

    .card-panel-col {
      margin-bottom: 32px;
    }
    .card-panel {
      height: 150px;
      cursor: pointer;
      font-size: 12px;
      position: relative;
      overflow: hidden;
      color: #666;
      background: #fff;
      box-shadow: 4px 4px 40px rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.05);
      .title {
        text-align: center;
        line-height: 30px;
        font-size: 20px;
        margin-top: 10px;
      }
      .card-panel-icon-wrapper {
        float: left;
        margin: 14px 0 0 14px;
        padding: 16px;
        transition: all 0.38s ease-out;
        border-radius: 6px;
      }
      .card-panel-icon {
        float: left;
        font-size: 48px;
      }
      .card-panel-description {
        font-weight: bold;
        text-align: center;
        margin: 26px;
        margin-left: 0px;
        .card-panel-text {
          line-height: 18px;
          color: rgba(0, 0, 0, 0.45);
          font-size: 16px;
          margin-bottom: 12px;
        }
        .card-panel-num {
          font-size: 48px;
          color: #02adeb;
        }
      }
    }
  }
}
</style>
