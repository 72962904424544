<template lang="pug">
    div.dashboard-editor-container
        span(style="margin-left:30px") 今日数据:
          el-date-picker(style="margin-left:30px" value-format="yyyy-MM-dd" v-model="date" type="date" placeholder="选择日期")
          el-button.float-right(icon="el-icon-refresh" @click="onRefresh") 刷新
        el-row
          d1(:date="date" id="d1" ref="d1")
          d1(:date="date" id="d2" ref="d2")
          d1(:date="date" id="d3" ref="d3")
          d1(:date="date" id="d4" ref="d4")
          d1(:date="date" id="d5" ref="d5")
          d1(:date="date" id="d6" ref="d6")

</template>

<script>
import D1 from "./widgets/d1";
import { formatDate } from "../../filters";
// import D2 from "./widgets/d2";
// import D3 from "./widgets/d3";
// import D4 from "./widgets/d4";

export default {
  name: "Dashboard",
  data: () => ({
    date: formatDate(new Date())
  }),
  components: { D1 },
  methods: {
    onRefresh() {
      [1, 2, 3, 4, 5, 6].forEach(e => {
        this.$refs["d" + e].refresh();
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  height: 100%;
  position: absolute;
  width: 100%;
}
</style>
